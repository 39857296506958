@import "media-queries";
@import "typography";
@import "variables";

.flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.fill-height {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    align-content: stretch;
    //min-height: 100%;
    //height: auto;
}

.page-center {
    //min-width: $breakpoint-small;
    //width: 100%;
    //max-width: $breakpoint-extra-large;
    min-width: $breakpoint-small;
    max-width: rem(1150px);
    width: 100%;
    margin: 0 auto;

    @media #{$large-up} {
        //width: rem(1150px);
    }
}

.white-sheet-content {
    @extend .page-center;
    @extend .fill-height;
    background-color: white;

    .ups-page-header {
        padding: rem(18px) $body-padding-small;

        @media #{$medium-up} {
            padding: rem(42px) rem(32px);
        }
    }
}

.white-content-page {
    background-color: white;
    min-height: 100%;
    @extend .fill-height;

    .ups-page-header {
        @extend .page-center;
        padding-bottom: 1em;
        min-width: auto;
    }

    .ups-content-block {
        @extend .page-center;
        //width: 100%;
        padding: 0 rem(32px) 2em;
    }
}