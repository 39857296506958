$breakpoint-small: 600px;
$breakpoint-medium: 1024px;
$breakpoint-large: 1440px;
$breakpoint-extra-large: 1920px;
$xsmall-max: calc(#{$breakpoint-small} - 1px);
$small-max: calc(#{$breakpoint-medium} - 1px);
$medium-max: calc(#{$breakpoint-large} - 1px);
$large-max: calc(#{$breakpoint-extra-large} - 1px);

$xsmall-up: 'screen and (min-width: 0px)';
$xsmall-only: 'screen and (min-width: 0px) and (max-width: #{$xsmall-max})';
$xsmall-down: 'screen and (max-width: #{$xsmall-max})';
$small-up: 'screen and (min-width: #{$breakpoint-small})';
$small-only: 'screen and (min-width: #{$breakpoint-small}) and (max-width: #{$small-max})';
$small-down: 'screen and (max-width: #{$small-max})';
$medium-up: 'screen and (min-width: #{$breakpoint-medium})';
$medium-only: 'screen and (min-width: #{$breakpoint-medium}) and (max-width: #{$medium-max})';
$medium-down: 'screen and (max-width: #{$medium-max})';
$large-up: 'screen and (min-width: #{$breakpoint-large})';
$large-only: 'screen and (min-width: #{$breakpoint-large}) and (max-width: #{$large-max})';
$large-down: 'screen and (max-width: #{$large-max})';
$xlarge-up: 'screen and (min-width: #{$breakpoint-extra-large})';

@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/_variables';
@import 'bootstrap/scss/mixins/_breakpoints';
