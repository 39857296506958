@import "colors";
@import "media-queries";
@import "typography";

$side-nav-width: 20em;
$body-padding-small: 23px;
$body-padding-medium-up: 32px;

/* CONTROL FONT SIZE ON DIFFERENT SCREEN SIZES */
/* Reference: https://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/ */
$breakpoints: (
  small : 576px,
  medium: 768px,
  large : 992px,
  xlarge: 1200px
);

$desktop-09: (
  null: 9px,
  small: 9px,
  medium: 9px,
  large: 12px,
  xlarge: 12px
);


$desktop-10: (
  null: 10px,
  small: 10px,
  medium: 10px,
  large: 10px,
  xlarge: 10px
);


$desktop-11: (
  null: 11px,
  small: 11px,
  medium: 11px,
  large: 11px,
  xlarge: 11px
);

$desktop-12: (
  null: 12px,
  small: 12px,
  medium: 12px,
  large: 12px,
  xlarge: 12px
);

$desktop-14: (
  null: 14px,
  small: 14px,
  medium: 14px,
  large: 14px,
  xlarge: 14px
);

$desktop-15: (
  null: 14px,
  small: 14px,
  medium: 15px,
  large: 15px,
  xlarge: 15px
);

$desktop-16: (
  null: 14px,
  small: 14px,
  medium: 16px,
  large: 16px,
  xlarge: 16px
);

$desktop-18: (
  null: 16px,
  small: 16px,
  medium: 18px,
  large: 18px,
  xlarge: 18px
);

/* Desktop-20 refers to the given font-size (20) for desktop screens from the UI/UX design. */
$desktop-20: (
  null: 16px,
  small: 16px,  //We make the smaller screen sizes have smaller px
  medium: 20px, //We default everything above small to the given desktop font size
  large: 20px,
  xlarge: 20px
);

$desktop-22: (
  null: 18px,
  small: 18px,
  medium: 22px,
  large: 22px,
  xlarge: 22px
);

$desktop-24: (
  null: 20px,
  small: 20px,
  medium: 24px,
  large: 24px,
  xlarge: 24px
);

$desktop-26: (
  null: 21px,
  small: 21px,
  medium: 26px,
  large: 26px,
  xlarge: 26px
);

$desktop-28: (
  null: 22px,
  small: 22px,
  medium: 28px,
  large: 28px,
  xlarge: 28px
);

$desktop-30: (
  null: 24px,
  small: 24px,
  medium: 30px,
  large: 30px,
  xlarge: 30px
);

$desktop-32: (
  null: 26px,
  small: 26px,
  medium: 32px,
  large: 32px,
  xlarge: 32px
);

$desktop-35: (
  null: 26px,
  small: 26px,
  medium: 35px,
  large: 35px,
  xlarge: 35px
);

$desktop-40: (
  null: 32px,
  small: 32px,
  medium: 40px,
  large: 40px,
  xlarage: 40px
);

$desktop-48: (
  null: 38px,
  small: 38px,
  medium: 48px,
  large: 48px,
  xlarage: 48px
);

$desktop-50: (
  null: 32px,
  small: 32px,
  medium: 40px,
  large: 40px,
  xlarage: 50px
);

@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      font-size: $fs-font-size;
    }
    @else {
      // If $fs-font-size is a key that exists in
      // $fs-breakpoints, use the value
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @media screen and (min-width: $fs-breakpoint) {
        font-size: $fs-font-size;
      }
    }
  }
}
/*************************************************/
