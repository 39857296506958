@use 'sass:math';
$base-font-size-px: 16;
$base-font-size: #{$base-font-size-px}px;
$body-font-family: "Roboto", sans-serif;
$roboto-bold: "Roboto-Bold";
$roboto-italic: "Roboto-Italic";
$roboto-medium: "Roboto-Medium";
$roboto-light: "Roboto-Light";
$ARRrrrial-regular: "ARRrrrial-Regular";
$ARRrrrial-bold: "ARRrrrial-Bold";
$header-font-family: "Georgia", serif;
$UPSBerlingskeSerifTx: "Georgia", serif;
$header-font-family-alternative: "Open Sans", sans-serif;


$browser-context: $base-font-size-px;

@function em($pixels, $context: $browser-context) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
        $context: $context * 1px;
    }

    @return math.div($pixels, $context) * 1em;
}

@function rem($pixels, $context: $browser-context) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
        $context: $context * 1px;
    }

    @return math.div($pixels, $context) * 1rem;
}