@import "variables";

.mat-form-fields {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;

  .mat-form-field {
    width: 100%;

    &.medium-full {
      @media #{$medium-up} {
        width: 100%;
      }
    }

    &.medium-70 {
      @media #{$medium-up} {
        width: 69%;
      }
    }

    &.medium-half,
    &.medium-50 {
      @media #{$medium-up} {
        width: 49%;
      }
    }

    &.medium-30 {
      @media #{$medium-up} {
        width: 29%;
      }
    }

    &.medium-quarter,
    &.medium-25 {
      @media #{$medium-up} {
        width: 24%;
      }
    }

    &.medium-fifth,
    &.medium-20 {
      @media #{$medium-up} {
        width: 19%;
      }
    }
  }
}

.mat-form-field {
  &.mat-focused {
    .mat-form-field-required-marker {
      color: $ups-warning-red;
    }
  }

  &.full-width {
    width: 100%;
  }
}

.mat-radio-button {
  @include font-size($desktop-16);   
  color: $ups-bray !important;
  font-family: $roboto-medium;
 }

 .mat-radio-button.mat-accent.mat-radio-checked {
   .mat-radio-container {
     .mat-radio-inner-circle {
      background-color: white;
      transform: scale(0.35); 
     }
     .mat-radio-outer-circle {
       border-color: $primary;
       background-color: $primary; 
     }
     .mat-ripple {
      display: none;
     }
   }
 }

.file-selector {
  position: relative;
  overflow: hidden;
  width: 100%;

  .file-input {
    position: absolute;
    left: -9999px;
    height: 0;
    visibility: hidden;
  }

  .file-input-button {
    margin: 2px 0 3px 2px;
  }

  label {
    display: inline-block;
    font-size: 1rem;
    font-family: $roboto-bold;
    color: $primary;
    padding: .5rem 3rem;
    border: solid medium $primary;
    border-radius: 9999px;
  }
}
