/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "bootstrap/scss/bootstrap";
@import "src/styles/variables";
@import "src/styles/extendable";
@import "src/styles/form";

//Fonts start
@font-face {
  font-family: "Roboto";
  src: url(assets/fonts/Roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: "Roboto-Light";
  src: url(assets/fonts/Roboto/Roboto-Light.ttf);
}

@font-face {
  font-family: "Roboto-Medium";
  src: url(assets/fonts/Roboto/Roboto-Medium.ttf);
}

@font-face {
  font-family: "Roboto-Bold";
  src: url(assets/fonts/Roboto/Roboto-Bold.ttf);
}

@font-face {
  font-family: "Roboto-Italic";
  src: url(assets/fonts/Roboto/Roboto-Italic.ttf);
}

@font-face {
  font-family: "ARRrrrial-Regular";
  src: url(assets/fonts/ARRrrrial/ARRrrrialWeb-Regular_2.woff);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ARRrrrial-Bold";
  src: url(assets/fonts/ARRrrrial/ARRrrrialWeb-Bold_2.woff);
  font-weight: normal;
  font-style: normal;
}

.italic {
  font-family: $roboto-italic;
}

.bold {
  font-family: $roboto-bold;
}

//Fonts end

//Material icons START
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url(assets/google-icons/font/MaterialIcons-Regular.ttf) format("truetype"),
    url(assets/google-icons/font/MaterialIconsOutlined-Regular.otf) format("opentype"),
    url(assets/google-icons/font/MaterialIconsRound-Regular.otf) format("opentype"),
    url(assets/google-icons/font/MaterialIconsSharp-Regular.otf) format("opentype"),
    url(assets/google-icons/font/MaterialIconsTwoTone-Regular.otf) format("opentype");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

//Material icons END

//Get rid of the blue autofill color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
}

input:-internal-autofill-selected {
  background-color: white !important;
}

html,
body,
upsc-root {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

html {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  top: 0 !important;
  @include font-size($desktop-16);
}

body {
  color: $ups-brand-black !important;
  @include font-size($desktop-16);
  font-weight: normal;
  font-family: $body-font-family;
}

upsc-root {
  display: block;

  @include media-breakpoint-up(md) {
    height: 100%;
  }
}

//Allows mat dialog popups to be scrollable without the ugly scroll bar
.cdk-global-overlay-wrapper {
  overflow: auto;
}

//Just adjusts padding for all material dialog popups. The default padding is way too much.
.mat-dialog-container {
  padding: 0 !important;

  @include media-breakpoint-up(md) {
    padding: .25rem !important;
  }

  .billing-export-popup {
    padding: 1rem;
  }
}

.mat-dialog-container:has(.billing-export-popup) {
  overflow: hidden !important;
}

//Common link class
.upsc-link {
  text-decoration: underline;
  color: $ups-brand-blue;

  &:hover {
    cursor: pointer;
  }
}

//Common styles for all buttons
//In individual component styles, just adjust the width and height.
button {
  border: none;
  outline: none;

  &.upsc-button {
    background-color: $ups-green;
    font-family: $roboto-bold;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    border-radius: 37.5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40px;
    border: none;
    outline: none;

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      opacity: 0.4;
      cursor: default;
    }

    @include media-breakpoint-up(md) {
      font-size: 18px;
      height: 55px;
    }

    .button-content-wrapper {
      display: flex;
      align-items: center;

      mat-icon {
        color: rgba(99, 204, 197, 0.4);
        font-size: 18px;
        padding-top: 2px;
        margin-right: 5px;

        @include media-breakpoint-up(md) {
          font-size: 25px;
          padding-top: 0px;
          margin-right: 10px;
        }
      }

      .text-only {
        @include media-breakpoint-up(md) {}
      }
    }
  }

  &.upsc-rectangle-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $ups-green;
    color: white;
  }

  &.upsc-white-button {
    background-color: white;
    border: 2px solid $ups-green;
    color: $ups-green;
    border-radius: 23px;
    padding: .5rem 1rem;
    font-family: $roboto-medium;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 24px;
  }

  // NEW Button styles
  &.upsc-primary-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $ups-green;
    border: 2px solid $ups-green;
    border-radius: 23px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    font-family: $roboto-medium;
    min-width: 100px;
    min-height: 42px;
    padding: 0 20px;

    // Button states
    &:hover {
      cursor: pointer;
    }

    &:disabled {
      cursor: default;
      color: #AFB1BF;
      background-color: #EFEFF2;
      border: 2px solid #EFEFF2;

      mat-icon {
        color: #AFB1BF;
      }
    }

    &:focus {
      cursor: auto;
    }

    // If the button content includes a left/right chevron or a loading icon, include the appropriate class to ensure proper spacing
    &.icon-left {
      padding-left: 12px;
    }

    &.icon-right {
      padding-right: 12px;
    }

    // Loading icon
    .spinner-border {
      max-width: 20px;
      max-height: 20px;
      color: #E2E2E2;
      margin-left: .25rem;
      border-right-color: $ups-green !important;
    }

    // Mat icon
    mat-icon {
      color: white;
    }
  }

  &.upsc-secondary-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 2px solid $ups-green;
    border-radius: 23px;
    color: $ups-green;
    font-size: 16px;
    font-weight: 500;
    font-family: $roboto-medium;
    min-width: 100px;
    min-height: 42px;
    padding: 0 20px;

    // Button states
    &:hover {
      cursor: pointer;
    }

    &:disabled {
      cursor: default;
      color: #AFB1BF;
      background-color: #EFEFF2;
      border: 2px solid #AFB1BF;
    }

    &:focus {
      cursor: auto;
    }

    // If the button content includes a left/right chevron or a loading icon, include the appropriate class to ensure proper spacing
    &.icon-left {
      padding-left: 12px;
    }

    &.icon-right {
      padding-right: 12px;
    }

    // Mat icon
    mat-icon {
      color: $ups-green;
    }
  }
}

//common styles for all tables
table {
  &.upsc-table {
    border: 0;
    width: 100%;
    line-height: 1.3;
    word-break: break-word;
    overflow-wrap: break-word;
    -ms-word-break: break-all;

    th {
      background-color: #dddada;
      font-size: 14px;
      font-family: $roboto-medium;
      font-weight: normal;
      padding: 0.35rem;
      color: $ups-black-1;
    }

    tr:nth-child(even) {
      background: #ffffff;
    }

    tr:nth-child(odd) {
      background: #ededed;
    }

    .link {
      font-family: $body-font-family;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $ups-green;
      text-decoration: none;
      white-space: nowrap;
    }

    td {
      padding: 0.3rem;
      font-size: 14px;
      color: $ups-black-4;

      a:hover {
        cursor: pointer;
      }

      &.payment-data {
        text-align: center;
        padding-right: 25px;
      }

      &.no-results-found {
        font-size: 26px;
        line-height: 0.69;
        text-align: center;
      }

      &.modify-criteria {
        font-size: 19px;
        line-height: 0.69;
        text-align: center;
        font-family: $roboto-light;
      }
    }

    .icon {
      display: none;
    }
  }
}

//Common styles for accordion components
mat-accordion {
  &.upsc-accordion {
    .panel {
      box-shadow: none;

      &:nth-child(odd) {
        background: #ffffff;
      }

      &:nth-child(even) {
        background: $color-background-light;
      }

      .mat-expansion-panel-header {
        background-color: inherit;
      }
    }

    .panel-header {
      padding: 0px 15px;

      .panel-header-title {
        padding-right: 0px;
        margin-right: 0px;
        font-size: 13px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.23;
        letter-spacing: normal;
        color: #898989;
        width: 50%;
      }

      .panel-header-desc {
        margin-right: 0px;
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #898989;
        width: 50%;
      }

      .no-results-found {
        justify-content: center;
        color: rgb(36, 36, 36);
        font-size: 17px;
        text-align: center;
      }

      .modify-criteria {
        justify-content: center;
      }
    }
  }
}

//Common styles for tooltips
.ngb-tooltip-class .tooltip-inner {
  font-family: $body-font-family;
  font-size: 13px;
  color: $ups-black;
  background-color: $ups-brand-disabled-input-gray;
  box-shadow: 0 1px 3px 1px #D8D7DF;
  padding: 15px;
  line-height: 1.54;
  white-space: pre-wrap;
  text-align: left;
}

.ngb-tooltip-class.show {
  opacity: 1;
}

// Need to make slight customizations to the imported tooltip css classes to ensure that the arrow is visible
// tooltip with placement top
.bs-tooltip-top {
  padding: 0.4rem 0;

  .tooltip-arrow {
    bottom: 0;
    width: 0.8rem;
    height: 0.4rem;

    &::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
      top: 0;
      border-width: 0.4rem;
      border-top-color: $ups-brand-disabled-input-gray;
    }
  }
}

// tooltip with placement right
.bs-tooltip-end {
  padding: 0 0.4rem;

  .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;

    &::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
      right: 0;
      border-width: 0.4rem;
      border-right-color: $ups-brand-disabled-input-gray;
    }
  }
}

// tooltip with placement bottom
.bs-tooltip-bottom {
  padding: 0.4rem 0;

  .tooltip-arrow {
    top: 0;
    width: 0.8rem;
    height: 0.4rem;

    &::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
      bottom: 0;
      border-width: 0.4rem;
      border-bottom-color: $ups-brand-disabled-input-gray;
    }
  }
}

// tooltip with placement left
.bs-tooltip-start {
  padding: 0 0.4rem;

  .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;

    &::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
      left: 0;
      border-width: 0.4rem;
      border-left-color: $ups-brand-disabled-input-gray;
    }
  }
}

.bs-tooltip-auto {
  &[x-placement^="top"] {
    @extend .bs-tooltip-top;
  }

  &[x-placement^="right"] {
    @extend .bs-tooltip-right;
  }

  &[x-placement^="bottom"] {
    @extend .bs-tooltip-bottom;
  }

  &[x-placement^="left"] {
    @extend .bs-tooltip-left;
  }
}


// All checkbox styles START -------
input[type="checkbox"] {
  cursor: pointer;

  &.upsc-green-background-checkbox {
    accent-color: $ups-green;
  }

  // More specific styles for individual components
  &.size-22 {
    width: 22px;
    height: 22px;
  }
}

input[type="checkbox"]+label {
  position: relative;
  padding-left: 8px;
  cursor: pointer;
}

mat-checkbox.green-background-checkbox {
  &.mat-checkbox .mat-checkbox-frame {
    background-color: $ups-background-color;
  }

  &.mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: $ups-green;
    }
  }

  &.size-20 {
    .mat-checkbox-layout {
      .mat-checkbox-inner-container {
        width: 20px;
        height: 20px;
      }
    }
  }

  .mat-checkbox-layout {
    white-space: normal !important;
  }

  // More specific styles for individual components
  &.agreement-layout {
    .mat-checkbox-layout {
      margin-right: 5px;
      margin-bottom: 3px;
    }
  }

  &.advanced-search-layout {
    .mat-checkbox-layout {
      margin-right: 5px;
      margin-bottom: 3px;

      .mat-checkbox-label {
        line-height: 25px;
      }
    }

    .mat-checkbox-frame {
      border: 1px solid black;
    }

    .mat-checkbox-background,
    .mat-checkbox-frame {
      border-radius: 4px;
    }
  }

  &.onetime-payment-layout {
    .mat-checkbox-disabled {
      .mat-checkbox-label {
        color: inherit;
      }
    }

    @include media-breakpoint-down(xs) {
      .mat-checkbox-inner-container {
        height: 14px;
        width: 14px;
        margin-right: 2px !important;
      }
    }
  }

  &.create-account-layout {
    .mat-checkbox-layout {
      margin-bottom: 3px;
      margin-right: 5px;
    }

    @include media-breakpoint-down(sm) {
      .mat-checkbox-inner-container {
        width: 14px;
        height: 14px;
      }
    }
  }

  &.delivery-defense-layout {
    .mat-checkbox-layout {
      margin-bottom: 3px;
      margin-right: 5px;
    }

    .mat-checkbox-inner-container {
      width: 22px;
      height: 22px;
      margin-top: 0;
    }

    @include media-breakpoint-down(sm) {
      .mat-checkbox-inner-container {
        width: 14px;
        height: 14px;
      }
    }
  }

  &.edit-users-layout {
    .mat-checkbox-inner-container {
      margin: 3px 8px auto 0 !important;
    }
  }

  &.column-settings-unselectable {
    &.mat-checkbox-checked {
      .mat-checkbox-background {
        background-color: $ups-gray;
      }
    }
  }
}

mat-checkbox.green-background-checkbox-rounded {
  &.mat-checkbox .mat-checkbox-frame {
    background-color: $ups-background-color;
    border-radius: 20%;
    border: 1px solid $ups-background-color;
  }

  &.mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: $ups-green;
      border-radius: 20%;
      border: 1px solid $ups-background-color;
    }
  }

  .mat-checkbox-layout {
    white-space: normal !important;
  }

  // More specific styles for individual components
  &.size-30 {
    .mat-checkbox-layout {
      .mat-checkbox-inner-container {
        width: 30px;
        height: 30px;
      }
    }
  }

  &.size-25 {
    .mat-checkbox-layout {
      .mat-checkbox-inner-container {
        width: 25px;
        height: 25px;
      }
    }
  }

  &.claim-agreement-layout {
    .mat-checkbox-inner-container {
      margin: 5px 8px auto 0;
    }
  }
}

mat-checkbox.green-background-checkbox-rounded-black {
  &.mat-checkbox .mat-checkbox-frame {
    background-color: $ups-background-color;
    border-radius: 20%;
    border: 1px solid $ups-black;
  }

  &.mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: $ups-green;
      border-radius: 20%;
      border: 1px solid $ups-black;
    }
  }

  .mat-checkbox-layout {
    white-space: normal !important;
  }

  // More specific styles for individual components
  &.size-30 {
    .mat-checkbox-layout {
      .mat-checkbox-inner-container {
        width: 30px;
        height: 30px;
      }
    }
  }

  &.size-25 {
    .mat-checkbox-layout {
      .mat-checkbox-inner-container {
        width: 25px;
        height: 25px;
      }
    }
  }

  &.claim-agreement-layout {
    .mat-checkbox-inner-container {
      margin: 5px 8px auto 0;
    }
  }
}

mat-checkbox.light-green-background-checkbox-rounded {
  &.mat-checkbox .mat-checkbox-frame {
    background-color: $ups-background-color;
    border-radius: 20%;
    border: 1px solid $ups-background-color;
  }

  &.mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: $ups-green;
      border-radius: 20%;
      border: 1px solid $ups-green;
    }
  }

  .mat-checkbox-layout {
    white-space: normal !important;
  }

  .mat-checkbox-checkmark-path {
    stroke: white !important;
  }

  // More specific styles for individual components
  &.size-30 {
    .mat-checkbox-layout {
      .mat-checkbox-inner-container {
        width: 30px;
        height: 30px;
      }
    }
  }

  &.claim-agreement-layout {
    .mat-checkbox-inner-container {
      margin: 5px 8px auto 0;
    }
  }
}

mat-checkbox.white-background-checkbox {
  &.mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: $ups-background-color;
      border: 1px ridge $ups-background-color;

      svg path {
        stroke: $ups-green !important;
      }
    }
  }

  .mat-checkbox-layout {
    white-space: normal !important;
  }
}

// All checkbox styles END --------


// Common styles for mat-form-field regular inputs START ------

/** 
Notes: 
If using a DATE mat-form-field: include 'date' class.
**/

mat-form-field.mat-form-field-disabled:not(.date) {
  .mat-form-field-flex {
    background-color: $ups-brand-disabled-input-gray !important; //Disabled color
    border: 1px solid $ups-brand-disabled-input-border !important;
  }
}

mat-form-field.mat-form-field-type-mat-input,
mat-form-field.mat-form-field-type-mat-select {
  .mat-form-field-underline {
    // Remove default underlines
    display: none;
  }
}

mat-form-field.mat-form-field-type-mat-input:not(.date),
//Default styles for text inputs (Not date picker)
mat-form-field.mat-form-field-type-mat-select:not(.date) {

  //Default styles for select inputs (Not date picker)
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      border: 1px solid $ups-brand-gray-2; // Add our own border
      border-radius: 4px;
      padding: .5rem .75rem;
      background-color: white;
    }
  }

  input {
    color: $ups-brand-black !important;
  }
}

//Change the placeholder colors
.mat-form-field-appearance-legacy .mat-form-field-label,
.mat-form-field-label {
  color: $ups-brand-gray-2 !important;
}

mat-form-field.mat-focused {

  //When focused, add a little outline
  .mat-form-field-flex {
    outline: 1px solid $ups-brand-black !important;
  }
}

mat-form-field.ng-invalid.ng-touched:not(.mat-form-field-should-float),
mat-form-field.ng-invalid.ng-touched.ng-dirty {
  .mat-form-field-flex {
    border: 1px solid $ups-brand-error-red !important;
    outline: 1px solid $ups-brand-error-red !important;
  }

  .mat-form-field-label {
    color: $ups-brand-error-red !important;
  }
}

//Enable below code if they want certain color on placeholders when valid
mat-form-field.ng-valid.mat-form-field-should-float,
mat-form-field.mat-focused {
  .mat-form-field-label {
    color: $ups-green !important;
  }
}

// Common styles for mat-form-field regular inputs END ------


// Common mat-radio styles
mat-radio-group {
  mat-radio-button {
    margin-bottom: 0.5rem;

    .mat-radio-label-content {
      color: $ups-brand-black;
      font-family: $body-font-family;
      text-wrap: wrap;
    }

    .mat-radio-label {
      //Get rid of annoying margin that makes flex look weird
      margin-bottom: 0;
    }
  }
}

//mat-option styles
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: $ups-green !important;
}

//mat-icon styles
.mat-icon {
  overflow: unset !important; //icons default behavior is overflow hidden, which is incorrect
}

//mat-datepicker
mat-datepicker-content {
  .mat-calendar-body-selected {
    //Mainly just color changes
    background-color: $ups-green;
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: $ups-brand-light-green;
  }
}


// All pop-up styles START --------
.popup-global-layout {
  .global-title {
    @include font-size($desktop-40);
    color: $ups-black;
    word-break: break-word;
    overflow-wrap: break-word;
    -ms-word-break: break-all;
    line-height: 1.3;
    margin-right: 50px;
  }

  .global-green-bar {
    height: 4px;
    width: 72px;
    background-color: $ups-green;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  mat-icon.icon {
    width: 35px;
    height: 35px;
    font-size: 35px;

    @include media-breakpoint-up(md) {
      width: 50px;
      height: 50px;
      font-size: 50px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  mat-icon.icon-key {
    color: $ups-green;
  }

  mat-icon.icon-key2 {
    color: white;
  }

  .global-text {
    color: $ups-black;
    @include font-size($desktop-24);
    word-break: break-word;
    overflow-wrap: break-word;
    -ms-word-break: break-all;
    line-height: 1.3;
  }

  .global-button-format {
    margin-top: 2rem;
    display: flex;

    .upsc-white-button {
      width: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 24px;
      margin-right: 7px;

      &.auto {
        width: auto; // Honestly, all the buttons should be width auto be default. Will need to go back and check why it was initially hardcoded.
      }
    }

    .upsc-white-button2 {
      background-color: white;
      border: 2px solid $ups-green;
      color: $ups-green;
      border-radius: 23px;
      padding: 0px 10px 0px 0px;
      font-family: $roboto-medium;
      width: 118px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 7px;

      &.auto {
        width: auto; // Honestly, all the buttons should be width auto be default. Will need to go back and check why it was initially hardcoded.
      }
    }

    .upsc-green-popup-button {
      background-color: $ups-green;
      border: 2px solid $ups-green;
      color: white;
      border-radius: 23px;
      padding: .5rem 1rem;
      font-family: $roboto-medium;
      width: 120px;
      height: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 24px;

      &.width-176 {
        width: 176px;
      }

      &.width-224 {
        width: 224px;
      }

      &.auto {
        width: auto; // Honestly, all the buttons should be width auto be default. Will need to go back and check why it was initially hardcoded.
      }
    }

    .upsc-green-popup-button:disabled {
      border: 2px solid gray;
      background-color: gray;
    }
  }


  // Specific styles
  &.width-650 {
    border-radius: 2px;
    padding: 10px;
    max-width: 650px;

    @include media-breakpoint-up(md) {
      padding: 15px;
    }
  }

  &.width-600 {
    border-radius: 2px;
    padding: 10px;
    max-width: 600px;

    @include media-breakpoint-up(md) {
      padding: 15px;
    }
  }

  &.width-850 {
    border-radius: 2px;
    padding: 10px;
    max-width: 850px;

    @include media-breakpoint-up(md) {
      padding: 15px;
    }
  }

  &.width-950 {
    //for delivery-defense
    border-radius: 2px;
    padding: 1.5rem;
    max-width: 950px;

    @include media-breakpoint-up(md) {
      padding: 2rem;
    }
  }

  &.width-full {
    border-radius: 2px;
    padding: 10px;
    width: 100%;

    @include media-breakpoint-up(md) {
      padding: 15px;
    }
  }

  &.width-column-settings {
    border-radius: 2px;
    padding: 10px;
    width: auto;
    max-width: 500px;

    @include media-breakpoint-up(md) {
      padding: 15px;
      width: 500px;
    }
  }

  &.digital-wallet-TCs {
    border-radius: 2px;
    padding: 1.5rem;
    max-width: 850px;

    @include media-breakpoint-up(md) {
      padding: 2rem;
    }
  }
}

// All pop-up styles END --------  




/** ONLY PAGE-SPECIFIC STYLES FROM THIS POINT ONWARD. **/
/** Please keep globally shared styles above this. **/
/** 
    Usually page-specific styles are needed inside global if it is
    difficult to target certain selectors without ng-deep. Happens
    frequently with angular material components
**/


// Dashboard page
.dashboard-section {
  upsc-claim-list {
    .claims-container {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}

// Login page
.upsc-custom-login {
  upsc-notification-box {
    display: flex;
    justify-content: center;

    .box {
      max-width: 100%;
      margin-top: .5rem;
      padding: .5rem;

      @include media-breakpoint-up(md) {
        max-width: 360px;
      }
    }
  }

  .content {
    mat-form-field {
      .mat-form-field-subscript-wrapper {
        margin-top: 0;
      }
    }
  }
}

// claims-list table design
table {
  &.upsc-table-isop {
    border: 0;
    width: 100%;
    line-height: 1.3;
    word-break: break-word;
    overflow-wrap: break-word;
    -ms-word-break: break-all;
    overflow-x: scroll;

    .gray-icon {
      color: grey;
    }

    th {
      background-color: #4A4A4C;
      @include font-size($desktop-14);
      border-right: 1px solid white;
      font-weight: normal;
      padding: 1rem;
      color: #ffffff;
      height: 73px;
      min-width: 140px;
      white-space: nowrap;

      @include media-breakpoint-up(lg) {
        min-width: 160px;
      }

      @include media-breakpoint-up(xl) {
        min-width: 180px;
      }
    }

    .link1 {
      font-family: $body-font-family;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $ups-green;
      text-decoration: underline;
      white-space: nowrap;
    }

    .link {
      font-family: $body-font-family;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $ups-brand-blue;
      text-decoration: underline;
      white-space: nowrap;
    }


    td {
      padding: 0.75rem 1rem;
      @include font-size($desktop-14);
      color: $ups-black-4;
      min-width: 140px;

      @include media-breakpoint-up(lg) {
        min-width: 160px;
      }

      @include media-breakpoint-up(xl) {
        min-width: 180px;
      }

      a:hover {
        cursor: pointer;
      }

      &.payment-data {
        text-align: center;
        padding-right: 25px;
      }

      &.no-results-found {
        font-size: 26px;
        line-height: 0.69;
        text-align: center;
      }

      &.modify-criteria {
        font-size: 19px;
        line-height: 0.69;
        text-align: center;
        font-family: $roboto-light;
      }
    }

    tr {
      border-bottom: 1px solid #AFB1BF;
    }

    tr:not(:first-child) {
      background: var(--ups-brand-colors-ups-white, #FFF);
    }

    tr:last-child {
      border-bottom: none;
    }

    .icon {
      display: none;
    }

    .mat-icon {
      cursor: pointer;

      &.disable-cursor {
        cursor: not-allowed;
      }
    }
  }
}

// column settings
.column-wrapper {
  .mat-radio-label-content {
    width: 100%;
  }

  .mat-checkbox-layout {
    pointer-events: none;
  }

  .mat-mdc-text-field-wrapper {
    width: 330px;
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    top: -20px;
  }
}

//Contact-us -> Online Form. Styles have to be here because it loads on DOM outside of all wrappers.
//Panel-wrap is specified in support.component.ts
.panel-wrap .mat-dialog-container {
  border: solid 10px #eaeaea;
  border-radius: 0px;

  .mat-form-field-outline-start,
  .mat-form-field-outline-end {
    border-radius: 0 !important;
  }

  @include media-breakpoint-up(md) {
    min-width: 60vw;
    min-height: 50vh;
    max-height: 90vh;
    max-width: 80%;
    margin: 0 auto;
  }

  @include media-breakpoint-down(sm) {
    width: 100vw;
    max-height: 88vh;
  }
}

// claim list and billing account summary
.search-bar-spacing.mat-select-panel {
  //for mat-select dropdown, has to be outside claim list wrapper
  position: absolute;
  top: 32px;
  left: 4px;
  max-width: 212px;

  .mat-option {
    width: 212px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 300px;
    left: 2px;

    .mat-option {
      width: 300px;
    }
  }
}

.advanced-search-bar-spacing.mat-select-panel {
  //for mat-select dropdown, has to be outside claim list wrapper
  position: absolute;
  bottom: 30px;
  left: 4px;
  max-width: 230px;

  .mat-option {
    width: 230px;
  }

  &.multiple {
    left: 27px;
    max-width: 484px;

    @include media-breakpoint-down(sm) {
      max-width: 230px;
    }

    .mat-option {
      width: 484px;

      @include media-breakpoint-down(sm) {
        width: 230px;
      }
    }
  }
}

.account-summary-wrapper,
.transaction-history-wrapper {
  .search-bar {
    mat-form-field.mat-form-field.mat-form-field-type-mat-input:not(.date) .mat-form-field-wrapper .mat-form-field-infix {
      border-top: 0;
    }

    mat-form-field.mat-form-field.mat-form-field-type-mat-input:not(.date) .mat-form-field-wrapper .mat-form-field-flex,
    mat-form-field.mat-form-field-type-mat-select:not(.date) .mat-form-field-wrapper .mat-form-field-flex {
      border: 1px solid #4A4A4C;
      border-radius: 4px;
      padding: 0.5rem 0.75rem;
      padding-top: 0.8rem;
      background-color: white;
    }

    mat-form-field.mat-form-field.mat-form-field-type-mat-input.status:not(.date) .mat-form-field-wrapper .mat-form-field-flex,
    mat-form-field.mat-form-field-type-mat-select:not(.date) .mat-form-field-wrapper .mat-form-field-flex {
      border: 1px solid #4A4A4C;
      border-radius: 4px;
      padding: 0.5rem 0.75rem;
      // padding-top: 0.8rem;
      background-color: white;
    }
  }

  .search-category,
  .search-bar {
    .mat-select-arrow {
      opacity: 0;
    }
  }

  .toolbar-desktop {
    .mat-form-field-appearance-legacy .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }

  .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
    margin-top: 0.15rem;
  }

  .insights-slide-toggle {
    .mat-slide-toggle-bar {
      width: 44px;
      height: 26px;
      border-radius: 36px;
      background-color: #ffffff;
      border: 2px solid $ups-green;
    }

    .mat-slide-toggle-thumb {
      width: 16px;
      height: 16px;
      top: 6px;
      right: -4px;
      position: relative;
      background-color: $ups-green;
    }

    .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
      background-color: $ups-green;
    }

    .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
      background-color: white;
    }

    label.mat-slide-toggle-label {
      margin: 0;
    }

    .mat-slide-toggle-ripple {
      display: none;
      opacity: 0;
    }
  }
}

.is4ups-carrier-wrapper {
  .carrier-list {
    mat-form-field.mat-form-field.mat-form-field-type-mat-input:not(.date) .mat-form-field-wrapper .mat-form-field-infix {
      border-top: 0;
    }

    mat-form-field.mat-form-field.mat-form-field-type-mat-input:not(.date) .mat-form-field-wrapper .mat-form-field-flex,
    mat-form-field.mat-form-field-type-mat-select:not(.date) .mat-form-field-wrapper .mat-form-field-flex {
      padding-top: 1rem;
    }

    .mat-form-field-appearance-legacy .mat-form-field-wrapper {
      padding-bottom: 0.75rem;
    }
  }
}

.claim-list-wrapper {
  .search-bar {
    mat-form-field.mat-form-field.mat-form-field-type-mat-input:not(.date) .mat-form-field-wrapper .mat-form-field-infix {
      border-top: 0;
    }

    mat-form-field.mat-form-field.mat-form-field-type-mat-input:not(.date) .mat-form-field-wrapper .mat-form-field-flex,
    mat-form-field.mat-form-field-type-mat-select:not(.date) .mat-form-field-wrapper .mat-form-field-flex {
      border: 1px solid #4A4A4C;
      border-radius: 4px;
      padding: 0.5rem 0.75rem;
      padding-top: 0.8rem;
      background-color: white;
    }
  }

  .search-category {
    .mat-select-arrow-wrapper {
      opacity: 0;
    }
  }

  .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
    margin-top: 0.15rem;
  }

  .insights-slide-toggle {
    .mat-slide-toggle-bar {
      width: 44px;
      height: 26px;
      border-radius: 36px;
      background-color: #ffffff;
      border: 2px solid $ups-green;
      z-index: 0;
    }

    .mat-slide-toggle-thumb {
      width: 16px;
      height: 16px;
      top: 6px;
      right: -4px;
      position: relative;
      background-color: $ups-green;
    }

    .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
      background-color: $ups-green;
    }

    .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
      background-color: white;
    }

    label.mat-slide-toggle-label {
      margin: 0;
    }

    .mat-slide-toggle-ripple {
      display: none;
      opacity: 0;
    }
  }
}

.pagination-wrapper {
  mat-form-field.custom {
    .mat-form-field-infix {
      @include media-breakpoint-down(xl) {
        @include font-size($desktop-16);
        width: 30px;
        text-align: center;
        padding-top: 0;
      }

      @include media-breakpoint-down(md) {
        @include font-size($desktop-12);
        width: 25px;
        text-align: center;
        padding-top: 0;
      }
    }

    .mat-form-field-wrapper {
      .mat-form-field-flex {
        @include media-breakpoint-down(md) {
          padding: 0.1rem 0.2rem 0.15rem 0.2rem;
        }
      }
    }
  }
}

.profile-wrapper {
  .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
    margin-top: 0.15rem;
  }
}

//Trying to condense all claim-edit styles to this class
.claim-edit-wrapper {
  mat-form-field {

    //Checkmarks or suffix icons usually green
    .mat-icon {
      color: $ups-green;
    }
  }

  //For error notification box at top of screen
  upsc-notification-box {
    &.error-box {
      .box {
        display: flex;
        margin-bottom: 1rem;

        .icon {
          margin-right: .5rem;
        }
      }
    }
  }

  .process {
    upsc-notification-box {
      .box {
        margin-top: 1rem;
        display: flex;

        @include media-breakpoint-up(lg) {
          margin-top: 3rem;
        }

        @include media-breakpoint-up(xl) {
          margin-top: 5rem;
        }

        .icon {
          margin-right: 1rem;
        }

        .text {
          font-size: 14px;
          color: $ups-brand-black;
        }
      }
    }
  }
}

//One-time payment method page
.upsc-custom-payment-method {

  //Progress bar styles
  ul.steps {
    li.step {
      &.active {
        ~li.step {
          .order {
            border: solid medium #8C857E !important;
          }
        }
      }
    }
  }

  mat-progress-bar {
    &.mat-progress-bar {
      .mat-progress-bar-element {
        background-color: #8C857E;
      }
    }

    .mat-progress-bar-fill::after {
      padding-top: 6px;
      background-color: $ups-green;
    }
  }

  .invoices-styles {
    .style {
      .mat-checkbox-layout {
        .mat-checkbox-label {
          min-width: 56px;
        }
      }
    }

  }

  .total-amount {
    .mat-form-field-flex {
      width: 120px;
    }
  }
}

.transaction-history-wrapper {
  .search-category {
    .mat-select-arrow {
      opacity: 0;
    }
  }
}

// claim list and draft list wrapper
.main {
  .value-wrap {

    //Draft list only
    .searchWrapper {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      mat-form-field {
        width: 100%;

        @include media-breakpoint-up(md) {
          width: 400px;
        }

        .mat-form-field-wrapper {
          width: 100%;
        }

        .mat-form-field-suffix {
          cursor: pointer;
        }
      }
    }
  }
}

.add-child-or-shipper {
  mat-checkbox {
    label {
      margin-bottom: 0 !important
    }
  }
}

.advanced-wrapper {

  .amount-input,
  .currency-input {
    .mat-form-field-flex {
      width: 230px;
    }

    .mat-select-arrow {
      opacity: 0;
    }
  }

  .status-input {
    .mat-form-field-flex {
      width: 484px;

      @include media-breakpoint-down(sm) {
        width: 230px;
      }
    }

    .mat-select-arrow {
      opacity: 0;
    }
  }

  .receiver-input,
  .account-input,
  .invoice-input,
  .data-source-input {
    .mat-form-field-flex {
      width: 230px;

      .mat-form-field-infix {
        input {
          margin-bottom: 0.35rem;
        }
      }

      .mat-form-field-prefix {
        padding-right: 4px;
      }
    }
  }

  .data-source-input {

    .mat-select-placeholder,
    .mat-select-value {
      margin-bottom: .35rem;
    }
  }
}

.insureshield-footer-wrapper {
  mat-accordion {
    width: 100%;
  }

  .mat-expansion-panel {
    background: #4a4a4c;
  }

  .mat-expansion-panel-spacing {
    margin: 0;
  }

  .mat-expansion-panel-header {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    border-radius: 0;
  }

  .first-one {
    .mat-expansion-panel-header {
      border-top: 2px solid white;
    }
  }

  .last-one {
    .mat-expansion-panel-header {
      border-bottom: 2px solid white;
    }
  }
}

//Create account pages
.upsc-custom-create-account {
  .input-wrapper {

    //Styles for first page of create account
    mat-form-field {
      width: 250px;

      @include media-breakpoint-up(md) {
        width: 400px;
      }
    }
  }

  .input-container {

    //Styles for second page of create account
    .mat-form-field-flex {
      @include media-breakpoint-down(sm) {
        padding: 0 !important;
      }

      .mat-form-field-infix {
        width: unset;
        border-top: 0;

        input {
          height: 50px;

          @include media-breakpoint-up(md) {
            height: 70px;
          }
        }
      }
    }
  }

  .review {

    //Styles for last page of create account
    .mat-form-field-suffix {
      color: $ups-green;
    }
  }

  .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
    margin-top: .2rem !important;
  }
}

//my-choice
.my-choice-wrapper {
  mat-form-field.width-400 {
    width: 256px;

    @include media-breakpoint-up(sm, (sm:468px)) {
      width: 400px;
    }
  }
}

//customized claims portal
.ccp-landing-wrapper {
  mat-form-field.width-400 {
    width: 256px;

    @include media-breakpoint-up(sm, (sm:468px)) {
      width: 400px;
    }
  }

  mat-form-field.width-300 {
    width: 300px;

    @include media-breakpoint-up(sm,(sm:300px)) {
      width: 300px;   
    }
  }

  .input-container {

    .mat-form-field-flex {
      @include media-breakpoint-down(sm) {
        padding: 0 !important;
      }

      .mat-form-field-infix {
        width: unset;
        border-top: 0;

        input {
          height: 50px;

          @include media-breakpoint-up(md) {
            height: 70px;
          }
        }
      }
    }
  }
}

// Claim detail collapsible panels
upsc-collapsible-panel {

  //These are just some styles in claim detail collapsible panels, where user has to input info after chosing to re-open a claim.
  .mat-form-field-label {
    font-size: 14px;
  }

  .mat-form-field-infix {
    padding-top: 11px;
    padding-bottom: 0px;
  }

  .mat-input-element {
    font-size: 16px;
    height: 16px;
  }

  .panel-header {
    .width100 {
      display: flex;

      .mat-icon {
        &:hover {
          cursor: pointer;
        }
      }
    }

    .toggle .mat-icon {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.upsc-custom-claims-detail {
  upsc-collapsible-panel {
    .collapsible-panel {
      margin-bottom: 1.5rem;
    }
  }
}

//Used inside of product & privacy footer popups
.Rectangle .main {
  .mat-icon {
    float: right;

    &:hover {
      cursor: pointer;
    }
  }
}

//Grey navigation bar
.navigation-items {

  //This is for the tooltip on the # of claims that have messages (in the top nav)
  .ngb-tooltip-class {
    .arrow::before {
      border-top-color: $ups-bray;
      border-bottom-color: $ups-bray;
      border-right-color: white;
      z-index: 999999;
    }

    .tooltip-inner {
      font-family: $body-font-family;
      font-size: 13px;
      color: #8b8b8b;
      background-color: white;
      box-shadow: 0 1px 2px 0 #c4c4c4;
      padding: 5px;
    }
  }
}

//For the policy page and TUPSS policy page
.upsc-custom-policies {
  .collapsible-panel {
    //Putting this in the component styles wouldn't work for some reason
    margin-bottom: 1.5rem;
  }
}

//When user enters verification codes for tupss (tccp regular claim file & when clicking claim detail link from email)
.tccp-emailwrapper {
  .container-wrapper {
    .input-container {
      .mat-form-field-infix {
        border-top: none;
        display: flex;
        padding: .1rem !important;
        height: 50px;
        width: 35px;
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
          height: 70px;
          width: 50px;
          // padding: 1rem;
        }

        @include media-breakpoint-up(xl) {
          height: 110px;
          width: 75px;
          // padding: 1rem;
        }

        input {
          font-size: 24px;

          @include media-breakpoint-up(md) {
            font-size: 50px;
          }
        }
      }

      .center-text {
        text-align: center;
        color: rgba(89, 85, 85, 0.8);
        font-family: $body-font-family;
      }
    }
  }
}

//When user enters verification code using SHARED verification component
.shared.verification-wrapper {
  .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
    border-top: 0 !important;
  }
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}


@media screen and (max-width: 990px) {
  .tccp-emailwrapper__otp {
    .container-wrapper {
      .input-container {
        .mat-form-field-infix {
          border-top: none;
          display: flex;
          padding: .1rem !important;
          height: 75px !important;
          width: 75px !important;
          margin-bottom: 0;
        }

      }
    }
  }
}

@media screen and (max-width: 615px) {
  .tccp-emailwrapper__otp {
    .container-wrapper {
      .input-container {
        .mat-form-field-infix {
          border-top: none;
          display: flex;
          padding: .1rem !important;
          height: 35px !important;
          width: 25px !important;
          margin-bottom: 0;
        }

      }
    }
  }
}

//Just some alignment on ecp vs credit card choices
.payment-method-form {
  .mat-radio-label {
    align-items: unset;
  }
}

//Some margin fixes during payment method selections
.select-payment-method-wrapper {
  label.mat-radio-label {
    margin-bottom: 0;
  }
}

//Paginations are for manage-users
ngb-pagination {
  .page-link {
    background-color: white;
    border: none;
  }

  font-family: $roboto-medium;
}

//Paginations are for manage-users
ngb-pagination.mobile {
  display: block;

  @include media-breakpoint-up(md) {
    display: none;
  }

  .pagination {
    justify-content: center;

    input {
      width: 2.75rem !important;
    }
  }

  .page-link {
    color: black;
  }
}

//Paginations are for manage-users
ngb-pagination.desktop {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }

  .pagination {
    justify-content: flex-end;
  }

  .page-link {
    padding: .5rem 1rem;
    line-height: unset;
    color: black;

    &:hover {
      color: black;
      font-family: $roboto-bold;
    }
  }

  .page-item,
  .page-link {
    display: flex;
    align-items: center;
  }

  .page-item.active .page-link {
    color: black;
    background-color: #e9ecef;
    border: none;
    font-family: $roboto-bold;
  }
}

//Accordion styles for Prospect pages
.schedule-wrapper,
.help,
.prospect-landing-wrapper,
.activated-wrapper {
  .mat-expansion-panel {
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0px 4px 4px 0px #00000040 !important;
  }

  .mat-expansion-panel-body {
    display: flex;
    justify-content: center;
  }

  .mat-expansion-panel-header {
    height: 96px;
  }

  .mat-expansion-panel-header-title {
    margin-right: 0;
  }

  .mat-expansion-panel-content {

    .s32 {
      font-size: 26px !important;
      line-height: 1.2;

      @include media-breakpoint-up(md) {
        font-size: 32px !important;
        line-height: 1.2;
      }
    }
  }
}

.input-er-wrapper {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;

  .mdc-text-field--filled {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .req-fields {
    display: inline-flex;
    padding-left: 0px;
    padding-top: 5px;
    width: 400px;
    @include font-size($desktop-16);


    .redStar {
      color: #ff0000;
      padding-right: 5px;
    }

    @include media-breakpoint-down(sm) {
      width: 250px;
      @include font-size($desktop-14);
    }

    @include media-breakpoint-down(xs) {
      width: 200px;
      @include font-size($desktop-14);
    }
  }

  .error-input {
    display: inline-flex;
    padding-left: 0px;
    padding-top: 5px;
    width: 100%;

    .mat-icon {
      padding-top: 0px;
      padding-left: 0px;
      font-size: 21px;
    }

    .error-text {
      padding-left: 5px;
      width: 100%;
      @include font-size($desktop-12);
      padding-top: 5px;

      /*@include media-breakpoint-down(sm) {
        @include font-size($desktop-12);
      }*/
    }
  }

  .error-input-billing {
    display: inline-flex;
    padding-left: 0px;
    padding-top: 0px;
    width: 100%;

    .mat-icon {
      padding-top: 0px;
      margin-top: -1px;
      padding-left: 0px;
      font-size: 21px;
    }

    .error-text {
      padding-left: 5px;
      width: 100%;
      @include font-size($desktop-12);
      padding-top: 0px;

      /*@include media-breakpoint-down(sm) {
        @include font-size($desktop-12);
      }*/
    }
  }


}

.input-er-wrapper {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;

  .mdc-text-field--filled {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .req-fields {
    display: inline-flex;
    padding-left: 0px;
    padding-top: 5px;
    width: 400px;
    @include font-size($desktop-16);


    .redStar {
      color: #ff0000;
      padding-right: 5px;
    }

    @include media-breakpoint-down(sm) {
      width: 250px;
      @include font-size($desktop-14);
    }

    @include media-breakpoint-down(xs) {
      width: 200px;
      @include font-size($desktop-14);
    }
  }

  .error-input {
    display: inline-flex;
    padding-left: 0px;
    padding-top: 5px;
    width: 100%;

    .mat-icon {
      padding-top: 0px;
      padding-left: 0px;
      font-size: 21px;
    }

    .error-text {
      padding-left: 5px;
      width: 100%;
      @include font-size($desktop-12);
      padding-top: 5px;

      /*@include media-breakpoint-down(sm) {
        @include font-size($desktop-12);
      }*/
    }
  }


}

.input-er-step1-wrapper {
  padding-top: 5px;
  padding-bottom: 5px;
  //width: 100%;

  .req-fields {
    display: inline-flex;
    padding-left: 0px;
    padding-top: 5px;
    //width: 400px;
    @include font-size($desktop-16);


    .redStar {
      color: #ff0000;
      padding-right: 5px;
    }

    @include media-breakpoint-down(sm) {
      // width: 250px;
      @include font-size($desktop-14);
    }

    @include media-breakpoint-down(xs) {
      //width: 200px;
      @include font-size($desktop-14);
    }
  }

  .error-input {
    display: inline-flex;
    padding-left: 0px;
    padding-top: 5px;
    width: 100%;

    .mat-icon {
      padding-top: 0px;
      padding-left: 0px;
      font-size: 21px;
    }

    .error-text {
      padding-left: 10px;
      @include font-size($desktop-14);

      @include media-breakpoint-down(sm) {
        @include font-size($desktop-12);
      }
    }
  }
}


.invoice-status {
  color: white;
  font-size: 12px;
  padding: 5px 10px;
  text-align: center;
  border-radius: 20px;
  font-weight: normal;
  align-items: center;
  justify-content: center;
  display: inline-flex;

  @include media-breakpoint-up(md) {
    font-size: 14px;
    padding: 5px 10px;
  }

  &.error {
    background: #FCEAE6;
    color: #991C00;
    max-width: 200px;
    height: 30px;
    min-width: 130px;

    @include media-breakpoint-down(sm) {
      height: 25px;
      min-width: 100px;
    }
  }

  &.info {
    background: #F2F1EF;
    color: #121212;
    max-width: 200px;
    height: 30px;
    min-width: 130px;

    @include media-breakpoint-down(sm) {
      height: 25px;
      min-width: 100px;
    }
  }

  &.progress {
    background: #E6EFF8;
    color: #064D93;
    max-width: 200px;
    height: 30px;
    min-width: 130px;

    @include media-breakpoint-down(sm) {
      height: 25px;
      min-width: 100px;
    }
  }

  &.success {
    background: #D4EEDD;
    color: #10602C;
    max-width: 200px;
    height: 30px;
    min-width: 130px;

    @include media-breakpoint-down(sm) {
      height: 25px;
      min-width: 100px;
    }
  }

  &.warning {
    background: #FEF6CC;
    color: #615100;
    max-width: 200px;
    height: 30px;
    min-width: 130px;

    @include media-breakpoint-down(sm) {
      height: 25px;
      min-width: 100px;
    }
  }

  &.featured {
    background: #E7F2F2;
    color: #075A5A;
    max-width: 200px;
    height: 30px;
    min-width: 130px;

    @include media-breakpoint-down(sm) {
      height: 25px;
      min-width: 100px;
    }
  }
}

.check-circle-list {
  width: 100%;
  display: block;
  padding: 10px 10px 10px 10px;
  color: $ups-green;

  .plan-features {
    display: inline-flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;

    .icon {      

      @include media-breakpoint-down(lg) {
        font-size: 25px;
      }
      
      .mat-icon {
        color: $ups-green;
        font-size: 30px;

        @include media-breakpoint-down(lg) {
          font-size: 25px;
          width: 10%;
        }
      }
    }

    .text {
      width: 90%;
      padding-top: 5px;
      padding-left: 10px;
      color: $ups-black;

      @include media-breakpoint-down(xl) {
        @include font-size($desktop-14);
      }

      @include media-breakpoint-down(lg) {
        @include font-size($desktop-12);
        padding-left: 20px;
      }

    }
  }
}

.dd-button-container {
  width: 100%;
  display: block;
  padding: 10px 10px 20px 10px;

  @include media-breakpoint-down(md) {
    padding:10px 10px 0px 10px;
  }

  .plan-current {
    width: 200px;
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 15px;
    padding-left: 0px;
    @include font-size($desktop-24);

    @include media-breakpoint-down(lg) {
      width: 150px;
      @include font-size($desktop-22);
    }

    @include media-breakpoint-down(md) {
      width: 100px;
      @include font-size($desktop-18);
      padding-top: 0px;
    }

    @include media-breakpoint-down(sm) {
      width: 80px;
      @include font-size($desktop-16);
      padding-top: 0px;
    }
  }

  .upsc-button {
    width: 200px;
    height: 50px;
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 15px;
    padding-left: 20px;

    @include media-breakpoint-down(lg) {
      width: 150px;
      @include font-size($desktop-14);
    }

    @include media-breakpoint-down(md) {
      width: 100px;
      @include font-size($desktop-14);
    }

    @include media-breakpoint-down(sm) {
      width: 60px;
      @include font-size($desktop-12);
    }

    .btn-text-icon {
      display: inline-flex;

      .text {
        @include media-breakpoint-down(sm) {
          padding-top: 0px;
        }

        @include media-breakpoint-down(lg) {
          padding-top: 3px;
        }
      }

      .mat-icon {
        padding-top: 0px;
        padding-left: 5px;
      }
    }
  }
}